@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, #root {
    height: 100%;
    line-height:1.5;
    /*-webkit-text-size-adjust:100%;*/
    tab-size:4;
    font-family:Inter,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
    /*font-feature-settings:normal;*/
    /*font-variation-settings:normal*/
}


body {
    margin:0;
    line-height:inherit;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));

}

@media (prefers-color-scheme: dark) {
    body {
        background-color: rgb(15 23 42 / var(--tw-bg-opacity));
    }
}

@layer components {
    .absolute-center {
        @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2;
    }
}
